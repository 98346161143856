import React from 'react';
import { PathService } from '../../services';
import { InTheNewsPostContext, TemplateProps } from '../../types';
import { InTheNewsPostView } from '../../views';

const InTheNewsPost: React.FunctionComponent<
  TemplateProps<InTheNewsPostContext>
> = ({ pageContext, location }) => {
  PathService.set(location.pathname);
  return (
    <InTheNewsPostView
      news={JSON.parse(pageContext.news)}
      relatedNews={JSON.parse(pageContext.relatedNews)}
    />
  );
};

export default InTheNewsPost;
